$phone-width: rem(240px);
$phone-height: rem(480px);
$phone-width-desktop: rem(300px);
$phone-height-desktop: rem(600px);

.phone {
  position: relative;
  @include media($only-mobile-tablet) {
    @include size($phone-width, $phone-width * 2);
    display: block;
    margin-bottom: -rem(150px);
    margin-left: auto;
    margin-right: auto;
  }
  @include media($desktop) {
    @include size($phone-width-desktop, $phone-width-desktop * 2);
    position: sticky;
    top: 20vh;
    margin-right: auto;
    margin-bottom: -rem(50px);
    margin-left: auto;
    z-index: 0;
  }

  &::after {
    @include size(90%);
    display: block;
    position: absolute;
    top: 10%;
    left: 5%;
    content: '';
    border-radius: rem(50px);
    z-index: -1;
  }

}

.phone__screen {
  position: absolute;
  top: rem(8px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: rem(2px);
  @include media($only-mobile-tablet) {
    width: rem(222px);
  }
  @include media($desktop) {
    top: rem(12px);
    height: 610px;
  }
}

.phone__screen--1 {
  z-index: 30;
}

.phone__device {
  max-width: 100%;
  z-index: 40;
  position: relative;
}
