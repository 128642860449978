.form-control {
  @include typi('normal');
  font-weight: bold;
  color: $inverse-text-color;

  &::placeholder {
    font-family: $font-family-base;
    font-weight: bold;
  }
}

.has-danger {

  .form-control {
    color: $state-danger-text;
    border: rem(2px) solid $rojo;
    &::placeholder {
      color: $state-danger-text;
    }

  }

}

.form-control-feedback {
  padding-top: spacing(tiny) ;
  padding-bottom: spacing(tiny);
  padding-left: spacing(xsmall);
  border: rem(1px) solid $state-danger-text;
  border-radius: 0;
  background-color: $state-danger-bg;
  color: $state-danger-text;
}
.custom-control.custom-checkbox {
  display: flex;
}
.custom-control-description {
 display: block;
 align-self: flex-end;
}
