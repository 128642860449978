/*
  Usado para comprobar el ritmo vertical
  Generalmente se aplica a la etiqueta html

  $line-height: separación entre las líneas (en píxeles)
  $offset: ajuste del comienzo de la primera línea (en píxeles)
  $line-color: color de las líneas horizontales
*/
@mixin baseline-grid($line-height, $offset: 0, $line-color: red) {
  $line-height-minus: $line-height - 1;

  background-image: linear-gradient(to bottom, transparent 0, transparent $line-height-minus, $line-color $line-height-minus, $line-color $line-height);

  background-size: 100% $line-height;
  background-position: 0 $offset;
}
