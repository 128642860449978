
//base
.u-padding-top-base {
  padding-top: spacing(base) !important;
}

.u-padding-bottom-base {
  padding-bottom: spacing(base) !important;
}

//medium
.u-padding-bottom-medium {
  padding-bottom: spacing(medium) !important;
}
.u-padding-top-medium {
  padding-top: spacing(medium) !important;
}

//large
.u-padding-bottom-large {
  padding-bottom: spacing(large) !important;
}

.u-padding-top-large {
  padding-top: spacing(large) !important;
}

//xxhuge
.u-padding-top-xxhuge {
  padding-top: spacing(xxhuge) !important;
}

.u-padding-bottom-xxhuge {
  padding-bottom: spacing(xxhuge) !important;
}

.u-padding-left-none {
  padding-left: 0!important;
}

.u-padding-left-tiny {
  padding-left: spacing(tiny) !important;
}

.u-padding-left-xxhuge {
  padding-left: spacing(xxhuge) !important;
}

//BREAKPOINTS

.u-padding-top-large\@only-mobile {
  @include media($only-mobile) {
    padding-top: spacing(large) !important;
  }
}

.u-padding-top-large\@tablet  {
  @include media($tablet) {
    padding-top: spacing(large) !important;
  }
}

.u-padding-top-xxhuge\@tablet {
  @include media($tablet) {
    padding-top: spacing(xxhuge) !important;
  }
}

.u-padding-top-25vh\@tablet {
  @include media($tablet) {
    padding-top: 25vh !important;
  }
}

.u-padding-top-large\@only-mobile-tablet {
  @include media($only-mobile-tablet) {
    padding-top: spacing(large) !important;
  }
}

.u-padding-top-large\@desktop  {
  @include media($desktop) {
    padding-top: spacing(large) !important;
  }
}

.u-padding-top-huge\@desktop {
  @include media($desktop) {
    padding-top: spacing(huge) !important;
  }
}

.u-padding-top-xxhuge\@desktop {
  @include media($desktop) {
    padding-top: spacing(xxhuge) !important;
  }
}

.u-padding-top-25vh\@desktop {
  @include media($desktop) {
    padding-top: 25vh !important;
  }
}

.u-padding-top-25vh\@desktop {
  @include media($desktop) {
    padding-top: 25vh !important;
  }
}

.u-padding-bottom-large\@tablet {
  @include media($tablet) {
    padding-bottom: spacing(large) !important;
  }
}

.u-padding-bottom-huge\@tablet {
  @include media($tablet) {
    padding-bottom: spacing(huge) !important;
  }
}

.u-padding-bottom-xhuge\@tablet {
  @include media($tablet) {
    padding-bottom: spacing(xhuge) !important;
  }
}

.u-padding-bottom-xxhuge\@tablet {
  @include media($tablet) {
    padding-bottom: spacing(xxhuge) !important;
  }
}

.u-padding-bottom-large\@desktop  {
  @include media($desktop) {
    padding-bottom: spacing(large) !important;
  }
}

.u-padding-bottom-huge\@desktop {
  @include media($desktop) {
    padding-bottom: spacing(huge) !important;
  }
}

.u-padding-bottom-xxhuge\@desktop {
  @include media($desktop) {
    padding-bottom: spacing(xxhuge) !important;
  }
}







