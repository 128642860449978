body {
  background-image: url("/assets/images/background-fixed.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;

  &::before {
    @include size(100vw,100vh);
    display: block;
    position: fixed;
    content: "";
    z-index: -1;
    background-color: rgba(#fff,.5);
    @include media($desktop) {
      width: 50vw;
      background-color: $azul;
    }
  }

}
