// Genera estilos de utilidad de márgenes por cada tamaño de $spacing
$directions: ('-top', '-right', '-bottom', '-left');

@each $size in map-keys($spacing) {

  @each $direction in $directions {

    @if $size == 'base' {
      .u-margin#{$direction} {
        margin#{$direction}: map-get($spacing, $size) !important;
      }

      .u-undo-margin#{$direction} {
        margin#{$direction}: - map-get($spacing, $size) !important;
      }
    } @else {
      .u-margin#{$direction}-#{$size} {
        margin#{$direction}: map-get($spacing, $size) !important;
      }

      .u-undo-margin#{$direction}-#{$size} {
        margin#{$direction}: - map-get($spacing, $size) !important;
      }
    }
  }
}

@each $direction in $directions {
  .u-margin#{$direction}-none {
    margin#{$direction}: 0 !important;
  }
}

.u-margin-bottom-medium\@mobile-large {
  @include media($mobile-large) {
    margin-bottom: spacing(medium) !important;
  }
}

.u-margin-bottom-medium\@only-mobile {
  @include media($only-mobile) {
    margin-bottom: spacing(medium) !important;
  }
}

.u-margin-bottom-tiny\@tablet {
  @include media($tablet) {
    margin-bottom: spacing(tiny) !important;
  }
}
.u-margin-bottom-small\@tablet {
  @include media($tablet) {
    margin-bottom: spacing(small) !important;
  }
}


.u-margin-bottom\@tablet {
  @include media($tablet) {
    margin-bottom: spacing(base) !important;
  }
}

.u-margin-bottom-medium\@tablet {
  @include media($tablet) {
    margin-bottom: spacing(medium) !important;
  }
}

.u-margin-bottom-large\@tablet {
  @include media($tablet) {
    margin-bottom: spacing(large) !important;
  }
}

.u-margin-bottom-huge\@tablet {
  @include media($tablet) {
    margin-bottom: spacing(huge) !important;
  }
}

.u-margin-bottom-medium\@only-mobile-tablet {
  @include media($only-mobile-tablet) {
    margin-bottom: spacing(medium) !important;
  }
}

.u-margin-bottom-medium\@desktop {
  @include media($desktop) {
    margin-bottom: spacing(medium) !important;
  }
}

.u-margin-top-negative-phone-height\@desktop {
  @include media($desktop) {
    margin-top: -$phone-height-desktop !important;
  }
}
