.u-valign-super {
  vertical-align: super !important;
}

.u-valign-middle {
  vertical-align: middle !important;
}

.u-valign-baseline {
  vertical-align: baseline !important;
}

.u-uppercase {
  text-transform: uppercase !important;
}

.u-lowercase {
  text-transform: lowercase !important;
}

.u-capitalize {
  text-transform: capitalize !important;
}

.u-screen-reader-only {
  @include sr-only();
}

.u-font-bold {
  font-weight: bold !important;
}

.u-font-normal {
  font-weight: normal !important;
}

.u-font-italic {
  font-style: italic !important;
}

.u-text-decoration-none {
  text-decoration: none !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-center\@only-mobile {
  @include media($only-mobile) {
    text-align: center !important;
  }
}

.u-text-center\@tablet {
  @include media($tablet) {
    text-align: center !important;
  }
}

.u-text-center\@only-mobile-tablet {
  @include media($only-mobile-tablet) {
    text-align: center !important;
  }
}

.u-text-right {
  text-align: right !important;
}

.u-text-left {
  text-align: left !important;
}

.u-white-space-nowrap {
  white-space: nowrap;
}

.u-white-space-normal {
  white-space: normal !important;
}

.u-font-size-small {
  @include typi('small');
}

.u-font-color {
  color: $body-color !important;
}

.u-text-decoration-none {
  text-decoration: none !important;
}

.u-inverse-text-color {
  color: $inverse-text-color !important;
}

.u-inverse-text-color\@desktop {
  @include media($desktop) {
    color: $inverse-text-color !important;
  }
}

.u-list-style-none {
  list-style: none !important;
}
