$skew: 6;

.skew-section {
    padding-top: percentage($skew / 100);
    padding-bottom: percentage($skew / 100);
    position: relative;
}

.skew-section__container {
    transform: skew(0deg, -#{$skew}deg);
    background-color: green;
    z-index: 40;
}

.skew-section__content {
    padding-top: percentage($skew / 100);
    padding-bottom: percentage($skew / 100);
    transform: skew(0deg, #{$skew}deg);
}
