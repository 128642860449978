// Color de fondo y texto al seleccionar contenido en una página
*::selection {
  background: $selection-text-color;
}

@include typi-init;

body {
  margin: 0;

  @include typi('normal');
  @include antialiased;

  -webkit-text-size-adjust: 100%; /* stylelint-disable-line */
  -ms-text-size-adjust: 100%; /* stylelint-disable-line */
}

img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
}

address {
  margin-bottom: 0;
}

ol,
ul,
dl {
  margin-bottom: 0;
}

dd {
  margin-bottom: 0;
}

blockquote {
  margin-bottom: 0;
}

pre {
  margin-bottom: 0;
}

figure {
  margin-bottom: 0;
}

label {
  margin-bottom: 0;
}

legend {
  margin-bottom: 0;
}
