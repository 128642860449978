.js-carrusel-texto, 
.js-carrusel-texto-1, 
.js-carrusel-texto-2,
.js-carrusel-texto-3, 
.js-carrusel-texto-4,
.js-carrusel-texto-5, 
.js-carrusel-texto-6,
.js-carrusel-texto-7, 
.js-carrusel-texto-8 {
  line-height: 1.35em;
}