.u-background-color-azul {
  background-color: $azul !important;
}

.u-background-color-azul-oscuro {
  background-color: $azul-oscuro !important;
}

.u-background-color-blanco {
    background-color: $inverse-text-color !important;
}


.u-background-color-azul\@only-mobile {
  @include media($only-mobile) {
    background-color: $azul !important;
  }
}

.u-background-color-azul\@only-mobile-tablet {
  @include media($only-mobile-tablet) {
    background-color: $azul !important;
  }
}
