@charset "UTF-8";
/*
  Usado para comprobar el ritmo vertical
  Generalmente se aplica a la etiqueta html

  $line-height: separación entre las líneas (en píxeles)
  $offset: ajuste del comienzo de la primera línea (en píxeles)
  $line-color: color de las líneas horizontales
*/
/*
===========================================
==  Paleta de colores  ====================
===========================================
*/
/*
===========================================
==  breakpoints  ==========================
===========================================
*/
/*
===========================================
== fuentes ================================
===========================================
*/
/*
===========================================
==  márgenes  =============================
===========================================
*/
/*
===========================================
==  themes  ===============================
===========================================
*/
/*
===========================================
==  forms  ================================
===========================================
*/
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: Avenir, "Nunito Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #222;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: none !important;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

a {
  color: #41C6EF;
  text-decoration: none;
}

a:focus, a:hover {
  color: #12a5d2;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
}

[role="button"] {
  cursor: pointer;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
  background-color: transparent;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #636c72;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
textarea {
  line-height: inherit;
}

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
}

input[type="search"] {
  -webkit-appearance: none;
}

output {
  display: inline-block;
}

[hidden] {
  display: none !important;
}

@media print {
  *,
  *::before,
  *::after,
  p::first-letter,
  div::first-letter,
  blockquote::first-letter,
  li::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

@font-face {
  font-family: produkt;
  src: url("../fonts/produkt/Produkt-Regular.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: produkt;
  src: url("../fonts/produkt/Produkt-Bold.otf") format("otf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: produkt;
  src: url("../fonts/produkt/Produkt-Extralight.otf") format("otf");
  font-weight: 100;
  font-style: normal;
}

*::selection {
  background: #41C6EF;
}

html {
  font-size: 100%;
  line-height: 1.5;
}

body {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  /* stylelint-disable-line */
  -ms-text-size-adjust: 100%;
  /* stylelint-disable-line */
}

@media all and (min-width: 768px) {
  body {
    font-size: 1.25rem;
  }
}

img {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
}

address {
  margin-bottom: 0;
}

ol,
ul,
dl {
  margin-bottom: 0;
}

dd {
  margin-bottom: 0;
}

blockquote {
  margin-bottom: 0;
}

pre {
  margin-bottom: 0;
}

figure {
  margin-bottom: 0;
}

label {
  margin-bottom: 0;
}

legend {
  margin-bottom: 0;
}

body {
  background-image: url("/assets/images/background-fixed.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

body::before {
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  content: "";
  z-index: -1;
  background-color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 992px) {
  body::before {
    width: 50vw;
    background-color: #41C6EF;
  }
}

.black-link {
  color: black;
  font-size: 14px;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 5px;
  padding-left: 5px;
}

@media (min-width: 576px) {
  .container {
    padding-right: 7.5px;
    padding-left: 7.5px;
  }
}

@media (min-width: 768px) {
  .container {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (min-width: 576px) {
  .container {
    width: 540px;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}

.container-fluid {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 5px;
  padding-left: 5px;
}

@media (min-width: 576px) {
  .container-fluid {
    padding-right: 7.5px;
    padding-left: 7.5px;
  }
}

@media (min-width: 768px) {
  .container-fluid {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 992px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

@media (min-width: 576px) {
  .row {
    margin-right: -7.5px;
    margin-left: -7.5px;
  }
}

@media (min-width: 768px) {
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }
}

@media (min-width: 992px) {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}

@media (min-width: 1200px) {
  .row {
    margin-right: -20px;
    margin-left: -20px;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
}

@media (min-width: 576px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
    padding-right: 7.5px;
    padding-left: 7.5px;
  }
}

@media (min-width: 768px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (min-width: 992px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 1200px) {
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pull-0 {
  right: auto;
}

.pull-1 {
  right: 8.3333333333%;
}

.pull-2 {
  right: 16.6666666667%;
}

.pull-3 {
  right: 25%;
}

.pull-4 {
  right: 33.3333333333%;
}

.pull-5 {
  right: 41.6666666667%;
}

.pull-6 {
  right: 50%;
}

.pull-7 {
  right: 58.3333333333%;
}

.pull-8 {
  right: 66.6666666667%;
}

.pull-9 {
  right: 75%;
}

.pull-10 {
  right: 83.3333333333%;
}

.pull-11 {
  right: 91.6666666667%;
}

.pull-12 {
  right: 100%;
}

.push-0 {
  left: auto;
}

.push-1 {
  left: 8.3333333333%;
}

.push-2 {
  left: 16.6666666667%;
}

.push-3 {
  left: 25%;
}

.push-4 {
  left: 33.3333333333%;
}

.push-5 {
  left: 41.6666666667%;
}

.push-6 {
  left: 50%;
}

.push-7 {
  left: 58.3333333333%;
}

.push-8 {
  left: 66.6666666667%;
}

.push-9 {
  left: 75%;
}

.push-10 {
  left: 83.3333333333%;
}

.push-11 {
  left: 91.6666666667%;
}

.push-12 {
  left: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pull-sm-0 {
    right: auto;
  }
  .pull-sm-1 {
    right: 8.3333333333%;
  }
  .pull-sm-2 {
    right: 16.6666666667%;
  }
  .pull-sm-3 {
    right: 25%;
  }
  .pull-sm-4 {
    right: 33.3333333333%;
  }
  .pull-sm-5 {
    right: 41.6666666667%;
  }
  .pull-sm-6 {
    right: 50%;
  }
  .pull-sm-7 {
    right: 58.3333333333%;
  }
  .pull-sm-8 {
    right: 66.6666666667%;
  }
  .pull-sm-9 {
    right: 75%;
  }
  .pull-sm-10 {
    right: 83.3333333333%;
  }
  .pull-sm-11 {
    right: 91.6666666667%;
  }
  .pull-sm-12 {
    right: 100%;
  }
  .push-sm-0 {
    left: auto;
  }
  .push-sm-1 {
    left: 8.3333333333%;
  }
  .push-sm-2 {
    left: 16.6666666667%;
  }
  .push-sm-3 {
    left: 25%;
  }
  .push-sm-4 {
    left: 33.3333333333%;
  }
  .push-sm-5 {
    left: 41.6666666667%;
  }
  .push-sm-6 {
    left: 50%;
  }
  .push-sm-7 {
    left: 58.3333333333%;
  }
  .push-sm-8 {
    left: 66.6666666667%;
  }
  .push-sm-9 {
    left: 75%;
  }
  .push-sm-10 {
    left: 83.3333333333%;
  }
  .push-sm-11 {
    left: 91.6666666667%;
  }
  .push-sm-12 {
    left: 100%;
  }
  .offset-sm-0 {
    margin-left: 0%;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pull-md-0 {
    right: auto;
  }
  .pull-md-1 {
    right: 8.3333333333%;
  }
  .pull-md-2 {
    right: 16.6666666667%;
  }
  .pull-md-3 {
    right: 25%;
  }
  .pull-md-4 {
    right: 33.3333333333%;
  }
  .pull-md-5 {
    right: 41.6666666667%;
  }
  .pull-md-6 {
    right: 50%;
  }
  .pull-md-7 {
    right: 58.3333333333%;
  }
  .pull-md-8 {
    right: 66.6666666667%;
  }
  .pull-md-9 {
    right: 75%;
  }
  .pull-md-10 {
    right: 83.3333333333%;
  }
  .pull-md-11 {
    right: 91.6666666667%;
  }
  .pull-md-12 {
    right: 100%;
  }
  .push-md-0 {
    left: auto;
  }
  .push-md-1 {
    left: 8.3333333333%;
  }
  .push-md-2 {
    left: 16.6666666667%;
  }
  .push-md-3 {
    left: 25%;
  }
  .push-md-4 {
    left: 33.3333333333%;
  }
  .push-md-5 {
    left: 41.6666666667%;
  }
  .push-md-6 {
    left: 50%;
  }
  .push-md-7 {
    left: 58.3333333333%;
  }
  .push-md-8 {
    left: 66.6666666667%;
  }
  .push-md-9 {
    left: 75%;
  }
  .push-md-10 {
    left: 83.3333333333%;
  }
  .push-md-11 {
    left: 91.6666666667%;
  }
  .push-md-12 {
    left: 100%;
  }
  .offset-md-0 {
    margin-left: 0%;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pull-lg-0 {
    right: auto;
  }
  .pull-lg-1 {
    right: 8.3333333333%;
  }
  .pull-lg-2 {
    right: 16.6666666667%;
  }
  .pull-lg-3 {
    right: 25%;
  }
  .pull-lg-4 {
    right: 33.3333333333%;
  }
  .pull-lg-5 {
    right: 41.6666666667%;
  }
  .pull-lg-6 {
    right: 50%;
  }
  .pull-lg-7 {
    right: 58.3333333333%;
  }
  .pull-lg-8 {
    right: 66.6666666667%;
  }
  .pull-lg-9 {
    right: 75%;
  }
  .pull-lg-10 {
    right: 83.3333333333%;
  }
  .pull-lg-11 {
    right: 91.6666666667%;
  }
  .pull-lg-12 {
    right: 100%;
  }
  .push-lg-0 {
    left: auto;
  }
  .push-lg-1 {
    left: 8.3333333333%;
  }
  .push-lg-2 {
    left: 16.6666666667%;
  }
  .push-lg-3 {
    left: 25%;
  }
  .push-lg-4 {
    left: 33.3333333333%;
  }
  .push-lg-5 {
    left: 41.6666666667%;
  }
  .push-lg-6 {
    left: 50%;
  }
  .push-lg-7 {
    left: 58.3333333333%;
  }
  .push-lg-8 {
    left: 66.6666666667%;
  }
  .push-lg-9 {
    left: 75%;
  }
  .push-lg-10 {
    left: 83.3333333333%;
  }
  .push-lg-11 {
    left: 91.6666666667%;
  }
  .push-lg-12 {
    left: 100%;
  }
  .offset-lg-0 {
    margin-left: 0%;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pull-xl-0 {
    right: auto;
  }
  .pull-xl-1 {
    right: 8.3333333333%;
  }
  .pull-xl-2 {
    right: 16.6666666667%;
  }
  .pull-xl-3 {
    right: 25%;
  }
  .pull-xl-4 {
    right: 33.3333333333%;
  }
  .pull-xl-5 {
    right: 41.6666666667%;
  }
  .pull-xl-6 {
    right: 50%;
  }
  .pull-xl-7 {
    right: 58.3333333333%;
  }
  .pull-xl-8 {
    right: 66.6666666667%;
  }
  .pull-xl-9 {
    right: 75%;
  }
  .pull-xl-10 {
    right: 83.3333333333%;
  }
  .pull-xl-11 {
    right: 91.6666666667%;
  }
  .pull-xl-12 {
    right: 100%;
  }
  .push-xl-0 {
    left: auto;
  }
  .push-xl-1 {
    left: 8.3333333333%;
  }
  .push-xl-2 {
    left: 16.6666666667%;
  }
  .push-xl-3 {
    left: 25%;
  }
  .push-xl-4 {
    left: 33.3333333333%;
  }
  .push-xl-5 {
    left: 41.6666666667%;
  }
  .push-xl-6 {
    left: 50%;
  }
  .push-xl-7 {
    left: 58.3333333333%;
  }
  .push-xl-8 {
    left: 66.6666666667%;
  }
  .push-xl-9 {
    left: 75%;
  }
  .push-xl-10 {
    left: 83.3333333333%;
  }
  .push-xl-11 {
    left: 91.6666666667%;
  }
  .push-xl-12 {
    left: 100%;
  }
  .offset-xl-0 {
    margin-left: 0%;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

.o-container-full-width {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .o-container-full-width {
    padding-right: 22.5px;
    padding-left: 22.5px;
  }
}

@media (min-width: 768px) {
  .o-container-full-width {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (min-width: 992px) {
  .o-container-full-width {
    padding-right: 45px;
    padding-left: 45px;
  }
}

@media (min-width: 1200px) {
  .o-container-full-width {
    padding-right: 60px;
    padding-left: 60px;
  }
}

@media (max-width: 991px) {
  .o-row-full-width {
    margin-left: 0;
    margin-right: 0;
  }
}

.o-row-full-width__col {
  width: auto;
}

@media (max-width: 991px) {
  .o-row-full-width__col {
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .o-row-full-width__col {
    margin-left: -22.5px;
    margin-right: -22.5px;
    padding-left: 22.5px;
    padding-right: 22.5px;
  }
}

@media (max-width: 575px) {
  .o-row-full-width__col {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.7rem 1.5rem;
  font-size: 1rem;
  line-height: 2;
  color: #222;
  background-color: transparent;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #fff;
  border-radius: 1.75rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #464a4c;
  background-color: #fff;
  border-color: #5cb3fd;
  outline: none;
}

.form-control::placeholder {
  color: #fff;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #eceeef;
  opacity: 1;
}

.form-control:disabled {
  cursor: not-allowed;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
  color: #222;
  background-color: transparent;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: calc(0.7rem - 1px * 2);
  padding-bottom: calc(0.7rem - 1px * 2);
  margin-bottom: 0;
}

.col-form-label-lg {
  padding-top: calc(0.75rem - 1px * 2);
  padding-bottom: calc(0.75rem - 1px * 2);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem - 1px * 2);
  padding-bottom: calc(0.25rem - 1px * 2);
  font-size: 0.875rem;
}

.col-form-legend {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.form-control-static {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  margin-bottom: 0;
  line-height: 2;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-static.form-control-sm, .form-control-static.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: 1.8125rem;
}

.form-control-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]) {
  height: 3.1666666667rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
}

.form-check.disabled .form-check-label {
  color: #636c72;
  cursor: not-allowed;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
  cursor: pointer;
}

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}

.form-check-input:only-child {
  position: static;
}

.form-check-inline {
  display: inline-block;
}

.form-check-inline .form-check-label {
  vertical-align: middle;
}

.form-check-inline + .form-check-inline {
  margin-left: 0.75rem;
}

.form-control-feedback {
  margin-top: 0.25rem;
}

.form-control-success,
.form-control-warning,
.form-control-danger {
  padding-right: 4.5rem;
  background-repeat: no-repeat;
  background-position: center right 0.5625rem;
  background-size: 1.125rem 1.125rem;
}

.has-success .form-control-feedback,
.has-success .form-control-label,
.has-success .col-form-label,
.has-success .form-check-label,
.has-success .custom-control {
  color: #5cb85c;
}

.has-success .form-control {
  border-color: #5cb85c;
}

.has-success .input-group-addon {
  color: #5cb85c;
  border-color: #5cb85c;
  background-color: #eaf6ea;
}

.has-success .form-control-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%235cb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
}

.has-warning .form-control-feedback,
.has-warning .form-control-label,
.has-warning .col-form-label,
.has-warning .form-check-label,
.has-warning .custom-control {
  color: #f0ad4e;
}

.has-warning .form-control {
  border-color: #f0ad4e;
}

.has-warning .input-group-addon {
  color: #f0ad4e;
  border-color: #f0ad4e;
  background-color: white;
}

.has-warning .form-control-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23f0ad4e' d='M4.4 5.324h-.8v-2.46h.8zm0 1.42h-.8V5.89h.8zM3.76.63L.04 7.075c-.115.2.016.425.26.426h7.397c.242 0 .372-.226.258-.426C6.726 4.924 5.47 2.79 4.253.63c-.113-.174-.39-.174-.494 0z'/%3E%3C/svg%3E");
}

.has-danger .form-control-feedback,
.has-danger .form-control-label,
.has-danger .col-form-label,
.has-danger .form-check-label,
.has-danger .custom-control {
  color: #d9534f;
}

.has-danger .form-control {
  border-color: #d9534f;
}

.has-danger .input-group-addon {
  color: #d9534f;
  border-color: #d9534f;
  background-color: #fdf7f7;
}

.has-danger .form-control-danger {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23d9534f' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-inline .form-check-label {
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .form-inline .custom-control-indicator {
    position: static;
    display: inline-block;
    margin-right: 0.25rem;
    vertical-align: text-bottom;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.alert {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: bold;
}

.alert-dismissible .close {
  position: relative;
  top: -0.75rem;
  right: -1.25rem;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d0e9c6;
  color: #3c763d;
}

.alert-success hr {
  border-top-color: #c1e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bcdff1;
  color: #31708f;
}

.alert-info hr {
  border-top-color: #a6d5ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faf2cc;
  color: #8a6d3b;
}

.alert-warning hr {
  border-top-color: #f7ecb5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
}

.alert-danger hr {
  border-top-color: #e4b9b9;
}

.alert-danger .alert-link {
  color: #843534;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #eceeef;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #eceeef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .75;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.form-control {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: bold;
  color: #fff;
}

@media all and (min-width: 768px) {
  .form-control {
    font-size: 1.25rem;
  }
}

.form-control::placeholder {
  font-family: Avenir, "Nunito Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
}

.has-danger .form-control {
  color: #a94442;
  border: 0.125rem solid #a94442;
}

.has-danger .form-control::placeholder {
  color: #a94442;
}

.form-control-feedback {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  border: 0.0625rem solid #a94442;
  border-radius: 0;
  background-color: #f2dede;
  color: #a94442;
}

.custom-control.custom-checkbox {
  display: flex;
}

.custom-control-description {
  display: block;
  align-self: flex-end;
}

.title1 {
  font-size: 1.5625rem;
  line-height: 1.1;
  font-weight: 900;
  color: #222;
}

@media all and (min-width: 768px) {
  .title1 {
    font-size: 2.4375rem;
  }
}

.title2 {
  font-size: 1.25rem;
  line-height: 1.1;
  font-weight: 900;
  color: #222;
}

@media all and (min-width: 768px) {
  .title2 {
    font-size: 1.9375rem;
  }
}

.title3 {
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 900;
  color: #222;
}

@media all and (min-width: 768px) {
  .title3 {
    font-size: 1.5625rem;
  }
}

@media (min-width: 992px) {
  .title3\@desktop {
    font-size: 1rem;
    line-height: 1.1;
  }
}

@media all and (min-width: 992px) and (min-width: 768px) {
  .title3\@desktop {
    font-size: 1.5625rem;
  }
}

.phone {
  position: relative;
}

@media (max-width: 991px) {
  .phone {
    width: 15rem;
    height: 30rem;
    display: block;
    margin-bottom: -9.375rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 992px) {
  .phone {
    width: 18.75rem;
    height: 37.5rem;
    position: sticky;
    top: 20vh;
    margin-right: auto;
    margin-bottom: -3.125rem;
    margin-left: auto;
    z-index: 0;
  }
}

.phone::after {
  width: 90%;
  height: 90%;
  display: block;
  position: absolute;
  top: 10%;
  left: 5%;
  content: '';
  border-radius: 3.125rem;
  z-index: -1;
}

.phone__screen {
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.125rem;
}

@media (max-width: 991px) {
  .phone__screen {
    width: 13.875rem;
  }
}

@media (min-width: 992px) {
  .phone__screen {
    top: 0.75rem;
    height: 610px;
  }
}

.phone__screen--1 {
  z-index: 30;
}

.phone__device {
  max-width: 100%;
  z-index: 40;
  position: relative;
}

.button {
  font-size: 1rem;
  line-height: 1.1;
  display: inline-flex;
  align-items: center;
  height: 3.5rem;
  padding: 0 1.5rem;
  font-family: Avenir, "Nunito Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
  vertical-align: middle;
  background-color: #41C6EF;
  border: none;
  border-radius: 3.5rem;
  cursor: pointer;
  transition: all .3s;
}

.button:hover, .button:focus, .button:active {
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #14b7e9;
}

@media (max-width: 767px) {
  .button--full-width\@only-mobile {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

.button--primary {
  background-color: #174E5F;
}

.button--primary:hover, .button--primary:focus, .button--primary:active {
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #0d2c36;
}

.button--large {
  font-size: 1rem;
  line-height: 1.1;
  height: 4.375rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 4.375rem;
}

@media all and (min-width: 768px) {
  .button--large {
    font-size: 1.25rem;
  }
}

.button--small {
  font-size: 0.8125rem;
  line-height: 1.1;
  height: 2.25rem;
}

.icon {
  display: inline-block;
}

.icon--small {
  width: 1.125rem;
  height: 1.125rem;
}

.icon--base {
  width: 1.5rem;
  height: 1.5rem;
}

.icon--large {
  width: 3rem;
  height: 3rem;
}

.icon--xhuge {
  width: 6rem;
  height: 6rem;
}

.icon__blanco {
  fill: #fff;
}

@media (min-width: 768px) {
  .icon--xhuge\@tablet {
    width: 6rem;
    height: 6rem;
  }
}

.title1 {
  font-size: 1.5625rem;
  line-height: 1.1;
  font-weight: 900;
  color: #222;
}

@media all and (min-width: 768px) {
  .title1 {
    font-size: 2.4375rem;
  }
}

.title2 {
  font-size: 1.25rem;
  line-height: 1.1;
  font-weight: 900;
  color: #222;
}

@media all and (min-width: 768px) {
  .title2 {
    font-size: 1.9375rem;
  }
}

.title3 {
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 900;
  color: #222;
}

@media all and (min-width: 768px) {
  .title3 {
    font-size: 1.5625rem;
  }
}

@media (min-width: 992px) {
  .title3\@desktop {
    font-size: 1rem;
    line-height: 1.1;
  }
}

@media all and (min-width: 992px) and (min-width: 768px) {
  .title3\@desktop {
    font-size: 1.5625rem;
  }
}

.skew-section {
  padding-top: 6%;
  padding-bottom: 6%;
  position: relative;
}

.skew-section__container {
  transform: skew(0deg, -6deg);
  background-color: green;
  z-index: 40;
}

.skew-section__content {
  padding-top: 6%;
  padding-bottom: 6%;
  transform: skew(0deg, 6deg);
}

@media (max-width: 991px) {
  .site-header {
    background-color: #41C6EF;
  }
}

@media (min-width: 992px) {
  .site-header {
    width: 100%;
    position: absolute;
    top: 3rem;
    left: 0;
  }
}

@media (min-width: 992px) {
  .site-header__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.site-header__logo {
  width: 11.375rem;
}

@media (max-width: 991px) {
  .site-header__logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  .site-header__logo {
    width: 13.875rem;
  }
}

.site-header__languages {
  width: 15.5rem;
}

@media (max-width: 991px) {
  .site-header__languages {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #41C6EF;
  }
}

@media (min-width: 992px) {
  .site-header__languages {
    width: 15.5rem;
    float: right;
  }
}

@media (max-width: 991px) {
  .site-header__languages #languages {
    color: white;
    font-weight: bold;
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .site-header__languages #languages {
    color: #41C6EF;
    font-weight: bold;
    font-size: 12px;
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (10px * 2));
}

.modal-header {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.modal-title {
  font-size: 100%;
  line-height: 1.5;
}

.modal-body {
  font-size: 0.8125rem;
  line-height: 1.5;
  padding: 2.25rem;
}

@media all and (min-width: 768px) {
  .modal-body {
    font-size: 1rem;
  }
}

.link-language {
  cursor: pointer;
  border-width: 10;
  border-color: red;
  border-radius: 10;
}

.cookie-banner {
  position: fixed;
  left: 10%;
  top: 20px;
  right: 10%;
  width: 80%;
  padding: 5px 14px;
  display: inline-block;
  justify-content: space-between;
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.hidde {
  opacity: 0;
  display: none;
}

.cookie-buttons {
  text-align: center;
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.u-background-color-azul {
  background-color: #41C6EF !important;
}

.u-background-color-azul-oscuro {
  background-color: #174E5F !important;
}

.u-background-color-blanco {
  background-color: #fff !important;
}

@media (max-width: 767px) {
  .u-background-color-azul\@only-mobile {
    background-color: #41C6EF !important;
  }
}

@media (max-width: 991px) {
  .u-background-color-azul\@only-mobile-tablet {
    background-color: #41C6EF !important;
  }
}

.u-grid-undo-horizontal-margins {
  margin-left: -5px;
  margin-right: -5px;
}

@media (min-width: 576px) {
  .u-grid-undo-horizontal-margins {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
}

@media (min-width: 768px) {
  .u-grid-undo-horizontal-margins {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 992px) {
  .u-grid-undo-horizontal-margins {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 1200px) {
  .u-grid-undo-horizontal-margins {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.u-grid-add-horizontal-spacings {
  padding-left: 5px;
  padding-right: 5px;
}

@media (min-width: 576px) {
  .u-grid-add-horizontal-spacings {
    padding-left: 7.5px;
    padding-right: 7.5px;
  }
}

@media (min-width: 768px) {
  .u-grid-add-horizontal-spacings {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 992px) {
  .u-grid-add-horizontal-spacings {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  .u-grid-add-horizontal-spacings {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 576px) {
  .u-display-only-mobile-small {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .u-display-only-mobile {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .u-display-only-tablet-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .u-display-only-desktop-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .u-display-only-desktop-large-up {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .u-display-only-desktop-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .u-display-only-tablet-down {
    display: none !important;
  }
}

.u-display-inline-block {
  display: inline-block !important;
}

.u-display-inline {
  display: inline !important;
}

.u-display-none {
  display: none !important;
}

.u-display-inline-flex {
  display: inline-flex !important;
}

.u-display-flex {
  display: flex !important;
}

.u-flex-grow-1 {
  flex-grow: 1 !important;
}

.u-flex-grow-0 {
  flex-grow: 0 !important;
}

.u-flex-wrap {
  flex-wrap: wrap !important;
}

.u-flex-1 {
  flex: 1 !important;
}

.u-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.u-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.u-flex-direction-column {
  flex-direction: column !important;
}

.u-align-items-start {
  align-items: flex-start !important;
}

.u-align-items-center {
  align-items: center !important;
}

.u-align-items-end {
  align-items: flex-end !important;
}

.u-justify-conten-center {
  justify-content: center !important;
}

.u-justify-content-space-between {
  justify-content: space-between !important;
}

.u-justify-content-end {
  justify-content: flex-end !important;
}

.u-flex-order-2 {
  order: 2 !important;
}

.u-align-self-center {
  align-self: center !important;
}

.u-align-self-end {
  align-self: flex-end !important;
}

.js-carrusel-texto,
.js-carrusel-texto-1,
.js-carrusel-texto-2,
.js-carrusel-texto-3,
.js-carrusel-texto-4,
.js-carrusel-texto-5,
.js-carrusel-texto-6,
.js-carrusel-texto-7,
.js-carrusel-texto-8 {
  line-height: 1.35em;
}

.u-margin-top-tiny {
  margin-top: 0.375rem !important;
}

.u-undo-margin-top-tiny {
  margin-top: -0.375rem !important;
}

.u-margin-right-tiny {
  margin-right: 0.375rem !important;
}

.u-undo-margin-right-tiny {
  margin-right: -0.375rem !important;
}

.u-margin-bottom-tiny {
  margin-bottom: 0.375rem !important;
}

.u-undo-margin-bottom-tiny {
  margin-bottom: -0.375rem !important;
}

.u-margin-left-tiny {
  margin-left: 0.375rem !important;
}

.u-undo-margin-left-tiny {
  margin-left: -0.375rem !important;
}

.u-margin-top-xsmall {
  margin-top: 0.75rem !important;
}

.u-undo-margin-top-xsmall {
  margin-top: -0.75rem !important;
}

.u-margin-right-xsmall {
  margin-right: 0.75rem !important;
}

.u-undo-margin-right-xsmall {
  margin-right: -0.75rem !important;
}

.u-margin-bottom-xsmall {
  margin-bottom: 0.75rem !important;
}

.u-undo-margin-bottom-xsmall {
  margin-bottom: -0.75rem !important;
}

.u-margin-left-xsmall {
  margin-left: 0.75rem !important;
}

.u-undo-margin-left-xsmall {
  margin-left: -0.75rem !important;
}

.u-margin-top-small {
  margin-top: 1.125rem !important;
}

.u-undo-margin-top-small {
  margin-top: -1.125rem !important;
}

.u-margin-right-small {
  margin-right: 1.125rem !important;
}

.u-undo-margin-right-small {
  margin-right: -1.125rem !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.125rem !important;
}

.u-undo-margin-bottom-small {
  margin-bottom: -1.125rem !important;
}

.u-margin-left-small {
  margin-left: 1.125rem !important;
}

.u-undo-margin-left-small {
  margin-left: -1.125rem !important;
}

.u-margin-top {
  margin-top: 1.5rem !important;
}

.u-undo-margin-top {
  margin-top: -1.5rem !important;
}

.u-margin-right {
  margin-right: 1.5rem !important;
}

.u-undo-margin-right {
  margin-right: -1.5rem !important;
}

.u-margin-bottom {
  margin-bottom: 1.5rem !important;
}

.u-undo-margin-bottom {
  margin-bottom: -1.5rem !important;
}

.u-margin-left {
  margin-left: 1.5rem !important;
}

.u-undo-margin-left {
  margin-left: -1.5rem !important;
}

.u-margin-top-medium {
  margin-top: 2.25rem !important;
}

.u-undo-margin-top-medium {
  margin-top: -2.25rem !important;
}

.u-margin-right-medium {
  margin-right: 2.25rem !important;
}

.u-undo-margin-right-medium {
  margin-right: -2.25rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 2.25rem !important;
}

.u-undo-margin-bottom-medium {
  margin-bottom: -2.25rem !important;
}

.u-margin-left-medium {
  margin-left: 2.25rem !important;
}

.u-undo-margin-left-medium {
  margin-left: -2.25rem !important;
}

.u-margin-top-large {
  margin-top: 3rem !important;
}

.u-undo-margin-top-large {
  margin-top: -3rem !important;
}

.u-margin-right-large {
  margin-right: 3rem !important;
}

.u-undo-margin-right-large {
  margin-right: -3rem !important;
}

.u-margin-bottom-large {
  margin-bottom: 3rem !important;
}

.u-undo-margin-bottom-large {
  margin-bottom: -3rem !important;
}

.u-margin-left-large {
  margin-left: 3rem !important;
}

.u-undo-margin-left-large {
  margin-left: -3rem !important;
}

.u-margin-top-huge {
  margin-top: 4.125rem !important;
}

.u-undo-margin-top-huge {
  margin-top: -4.125rem !important;
}

.u-margin-right-huge {
  margin-right: 4.125rem !important;
}

.u-undo-margin-right-huge {
  margin-right: -4.125rem !important;
}

.u-margin-bottom-huge {
  margin-bottom: 4.125rem !important;
}

.u-undo-margin-bottom-huge {
  margin-bottom: -4.125rem !important;
}

.u-margin-left-huge {
  margin-left: 4.125rem !important;
}

.u-undo-margin-left-huge {
  margin-left: -4.125rem !important;
}

.u-margin-top-xhuge {
  margin-top: 6rem !important;
}

.u-undo-margin-top-xhuge {
  margin-top: -6rem !important;
}

.u-margin-right-xhuge {
  margin-right: 6rem !important;
}

.u-undo-margin-right-xhuge {
  margin-right: -6rem !important;
}

.u-margin-bottom-xhuge {
  margin-bottom: 6rem !important;
}

.u-undo-margin-bottom-xhuge {
  margin-bottom: -6rem !important;
}

.u-margin-left-xhuge {
  margin-left: 6rem !important;
}

.u-undo-margin-left-xhuge {
  margin-left: -6rem !important;
}

.u-margin-top-xxhuge {
  margin-top: 9rem !important;
}

.u-undo-margin-top-xxhuge {
  margin-top: -9rem !important;
}

.u-margin-right-xxhuge {
  margin-right: 9rem !important;
}

.u-undo-margin-right-xxhuge {
  margin-right: -9rem !important;
}

.u-margin-bottom-xxhuge {
  margin-bottom: 9rem !important;
}

.u-undo-margin-bottom-xxhuge {
  margin-bottom: -9rem !important;
}

.u-margin-left-xxhuge {
  margin-left: 9rem !important;
}

.u-undo-margin-left-xxhuge {
  margin-left: -9rem !important;
}

.u-margin-top-none {
  margin-top: 0 !important;
}

.u-margin-right-none {
  margin-right: 0 !important;
}

.u-margin-bottom-none {
  margin-bottom: 0 !important;
}

.u-margin-left-none {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .u-margin-bottom-medium\@mobile-large {
    margin-bottom: 2.25rem !important;
  }
}

@media (max-width: 767px) {
  .u-margin-bottom-medium\@only-mobile {
    margin-bottom: 2.25rem !important;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-tiny\@tablet {
    margin-bottom: 0.375rem !important;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-small\@tablet {
    margin-bottom: 1.125rem !important;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom\@tablet {
    margin-bottom: 1.5rem !important;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-medium\@tablet {
    margin-bottom: 2.25rem !important;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-large\@tablet {
    margin-bottom: 3rem !important;
  }
}

@media (min-width: 768px) {
  .u-margin-bottom-huge\@tablet {
    margin-bottom: 4.125rem !important;
  }
}

@media (max-width: 991px) {
  .u-margin-bottom-medium\@only-mobile-tablet {
    margin-bottom: 2.25rem !important;
  }
}

@media (min-width: 992px) {
  .u-margin-bottom-medium\@desktop {
    margin-bottom: 2.25rem !important;
  }
}

@media (min-width: 992px) {
  .u-margin-top-negative-phone-height\@desktop {
    margin-top: -37.5rem !important;
  }
}

.u-padding-top-base {
  padding-top: 1.5rem !important;
}

.u-padding-bottom-base {
  padding-bottom: 1.5rem !important;
}

.u-padding-bottom-medium {
  padding-bottom: 2.25rem !important;
}

.u-padding-top-medium {
  padding-top: 2.25rem !important;
}

.u-padding-bottom-large {
  padding-bottom: 3rem !important;
}

.u-padding-top-large {
  padding-top: 3rem !important;
}

.u-padding-top-xxhuge {
  padding-top: 9rem !important;
}

.u-padding-bottom-xxhuge {
  padding-bottom: 9rem !important;
}

.u-padding-left-none {
  padding-left: 0 !important;
}

.u-padding-left-tiny {
  padding-left: 0.375rem !important;
}

.u-padding-left-xxhuge {
  padding-left: 9rem !important;
}

@media (max-width: 767px) {
  .u-padding-top-large\@only-mobile {
    padding-top: 3rem !important;
  }
}

@media (min-width: 768px) {
  .u-padding-top-large\@tablet {
    padding-top: 3rem !important;
  }
}

@media (min-width: 768px) {
  .u-padding-top-xxhuge\@tablet {
    padding-top: 9rem !important;
  }
}

@media (min-width: 768px) {
  .u-padding-top-25vh\@tablet {
    padding-top: 25vh !important;
  }
}

@media (max-width: 991px) {
  .u-padding-top-large\@only-mobile-tablet {
    padding-top: 3rem !important;
  }
}

@media (min-width: 992px) {
  .u-padding-top-large\@desktop {
    padding-top: 3rem !important;
  }
}

@media (min-width: 992px) {
  .u-padding-top-huge\@desktop {
    padding-top: 4.125rem !important;
  }
}

@media (min-width: 992px) {
  .u-padding-top-xxhuge\@desktop {
    padding-top: 9rem !important;
  }
}

@media (min-width: 992px) {
  .u-padding-top-25vh\@desktop {
    padding-top: 25vh !important;
  }
}

@media (min-width: 992px) {
  .u-padding-top-25vh\@desktop {
    padding-top: 25vh !important;
  }
}

@media (min-width: 768px) {
  .u-padding-bottom-large\@tablet {
    padding-bottom: 3rem !important;
  }
}

@media (min-width: 768px) {
  .u-padding-bottom-huge\@tablet {
    padding-bottom: 4.125rem !important;
  }
}

@media (min-width: 768px) {
  .u-padding-bottom-xhuge\@tablet {
    padding-bottom: 6rem !important;
  }
}

@media (min-width: 768px) {
  .u-padding-bottom-xxhuge\@tablet {
    padding-bottom: 9rem !important;
  }
}

@media (min-width: 992px) {
  .u-padding-bottom-large\@desktop {
    padding-bottom: 3rem !important;
  }
}

@media (min-width: 992px) {
  .u-padding-bottom-huge\@desktop {
    padding-bottom: 4.125rem !important;
  }
}

@media (min-width: 992px) {
  .u-padding-bottom-xxhuge\@desktop {
    padding-bottom: 9rem !important;
  }
}

.u-valign-super {
  vertical-align: super !important;
}

.u-valign-middle {
  vertical-align: middle !important;
}

.u-valign-baseline {
  vertical-align: baseline !important;
}

.u-uppercase {
  text-transform: uppercase !important;
}

.u-lowercase {
  text-transform: lowercase !important;
}

.u-capitalize {
  text-transform: capitalize !important;
}

.u-screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.u-font-bold {
  font-weight: bold !important;
}

.u-font-normal {
  font-weight: normal !important;
}

.u-font-italic {
  font-style: italic !important;
}

.u-text-decoration-none {
  text-decoration: none !important;
}

.u-text-center {
  text-align: center !important;
}

@media (max-width: 767px) {
  .u-text-center\@only-mobile {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .u-text-center\@tablet {
    text-align: center !important;
  }
}

@media (max-width: 991px) {
  .u-text-center\@only-mobile-tablet {
    text-align: center !important;
  }
}

.u-text-right {
  text-align: right !important;
}

.u-text-left {
  text-align: left !important;
}

.u-white-space-nowrap {
  white-space: nowrap;
}

.u-white-space-normal {
  white-space: normal !important;
}

.u-font-size-small {
  font-size: 0.8125rem;
  line-height: 1.5;
}

@media all and (min-width: 768px) {
  .u-font-size-small {
    font-size: 1rem;
  }
}

.u-font-color {
  color: #222 !important;
}

.u-text-decoration-none {
  text-decoration: none !important;
}

.u-inverse-text-color {
  color: #fff !important;
}

@media (min-width: 992px) {
  .u-inverse-text-color\@desktop {
    color: #fff !important;
  }
}

.u-list-style-none {
  list-style: none !important;
}

.u-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.u-border-right-grey2 {
  border-right: 1px solid #e5e5e5;
}

.u-overflow-hidden {
  overflow: hidden !important;
}

@media (max-width: 767px) {
  .u-overflow-hidden\@only-mobile {
    overflow: hidden !important;
  }
}

@media (max-width: 991px) {
  .u-overflow-hidden\@only-mobile-tablet {
    overflow: hidden !important;
  }
}

.u-height-200 {
  height: 12.5rem !important;
}

@media (max-width: 991px) {
  .u-height-100\@only-mobile-tablet {
    height: 6.25rem !important;
  }
}

@media (max-width: 767px) {
  .u-height-130\@only-mobile {
    height: 8.125rem !important;
  }
}
