@font-face {
  font-family: produkt;
  src: url('../fonts/produkt/Produkt-Regular.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: produkt;
  src: url('../fonts/produkt/Produkt-Bold.otf') format('otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: produkt;
  src: url('../fonts/produkt/Produkt-Extralight.otf') format('otf');
  font-weight: 100;
  font-style: normal;
}
