////
/// @group text
////

/// Renderiza el texto en modo antialiased (suavizado)
@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin undo-font-smoothing {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

/// Configura un nuevo font-face
@mixin add-font-face($family-name, $path, $font-weight, $font-style: normal) {
  @font-face {
      font-family: $family-name;
      src:
        url($path + '.woff2') format('woff2'),
        url($path + '.woff') format('woff'),
        url($path + '.ttf') format('truetype');
      font-weight: $font-weight;
      font-style: $font-style;
  }
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content
// Credit: Bootstrap
@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
