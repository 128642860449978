/// Elimina los margenes dejados por el contenedor o una fila del grid para que
/// ocupe la anchura completa del contenedor
@mixin grid-undo-horizontal-spacings() {
  @each $breakpoint in map-keys($grid-gutter-widths) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($grid-gutter-widths, $breakpoint);
      @include horizontal-margin(-$gutter / 2);
    }
  }
}

/// Añade márgenes laterales
@mixin grid-add-horizontal-spacings() {
  @each $breakpoint in map-keys($grid-gutter-widths) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($grid-gutter-widths, $breakpoint);
      @include horizontal-padding($gutter / 2);
    }
  }
}

/// Elimina márgenes laterales de container y se asegura
/// que el width sea del 100%, incluyendo los márgenes eliminados
@mixin grid-element-full-width() {
  @include grid-undo-horizontal-spacings;

  @each $breakpoint in map-keys($grid-gutter-widths) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($grid-gutter-widths, $breakpoint);
      width: calc(100% + #{$gutter});
    }
  }
}
