
.icon {
  display: inline-block;
}

//xsmall 18px
.icon--small {
  width: spacing(small);
  height: spacing(small);
}

//24px base
.icon--base {
  width: spacing(base);
  height: spacing(base);
}

//48px large
.icon--large {
  width: spacing(large);
  height: spacing(large);
}

//xhuge 96px
.icon--xhuge {
  width:  spacing(xhuge);
  height: spacing(xhuge);
}

//color blanco
.icon__blanco {
  fill: $inverse-text-color;
}


//BREAKPOINT
.icon--xhuge\@tablet {
  @include media($tablet) {
    width:  spacing(xhuge);
    height: spacing(xhuge);
  }
}

