.cookie-banner {
    position: fixed;
    left: 10%;
    top: 20px;
    right: 10%;
    width: 80%;
    padding: 5px 14px;
    display: inline-block;
    justify-content: space-between;
    background-color: #eee;
    border-radius: 5px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);

}

.hidde {
  opacity: 0;
  display: none;
}

.cookie-buttons {
  text-align: center;
}