.site-header {
  @include media($only-mobile-tablet) {
    background-color: $azul;
  }
  @include media($desktop) {
    width: 100%;
    position: absolute;
    top: spacing(large);
    left: 0;
  }
}

.site-header__bar {
  @include media($desktop) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.site-header__logo {
  width: rem(182px);
  @include media($only-mobile-tablet) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: spacing(large);
  }
  @include media($desktop) {
    width: rem(222px);
  }
}

.site-header__languages {
  width: rem(248px);
  @include media($only-mobile-tablet) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: $azul;
  }
  @include media($desktop) {
    width: rem(248px);
    float: right;
  }

  #languages {
    @include media($only-mobile-tablet) {
      color: white;
      font-weight: bold;
      font-size: 12px;

    }
    @include media($desktop) {
      color: $azul;
      font-weight: bold;
      font-size: 12px;
    }
  }
}