$headings-color: $grey-1;

@mixin headings-style {
  font-weight: $black-weight;
  color: $headings-color;
}

.title1 {
  @include typi('h1');
  @include headings-style;
}

.title2 {
  @include typi('h2');
  @include headings-style;
}

.title3 {
  @include typi('h3');
  @include headings-style;
}

.title3\@desktop {
  @include media($desktop) {
    @include typi('h3');
  }
}
