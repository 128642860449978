$button-height: rem(56px);
$button-large-height: rem(70px);
$button-small-height: rem(36px);

.button {
  @include typi('button');

  display: inline-flex;
  align-items: center;
  height: $button-height;
  padding: 0 vr(1);

  font-family: $font-family-base;
  font-weight: 900;
  text-transform: uppercase;
  white-space: nowrap;
  color: $inverse-text-color;
  vertical-align: middle;

  background-color: $azul;
  border: none;
  border-radius: $button-height;
  cursor: pointer;
  transition: all .3s;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;

    color: $inverse-text-color;
    background-color: darken( $azul, 10% );
  }

}

.button--full-width\@only-mobile {
  @include media($only-mobile) {
    display: flex;
    width: 100%;
    justify-content: center;
  }
}

.button--primary {
  background-color: $azul-oscuro;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    outline: none;

    color: $inverse-text-color;
    background-color: darken( $azul-oscuro, 10% );
  }
}

.button--large {
  @include typi('button-large');
  height: $button-large-height;
  padding-left: vr(2);
  padding-right: vr(2);

  border-radius: $button-large-height;
}

.button--small {
  @include typi('button-small');
  height: $button-small-height;
}

