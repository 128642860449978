.u-overflow-hidden {
  overflow: hidden !important;
}

.u-overflow-hidden\@only-mobile {
  @include media($only-mobile) {
    overflow: hidden !important;
  }
}

.u-overflow-hidden\@only-mobile-tablet {
  @include media($only-mobile-tablet) {
    overflow: hidden !important;
  }
}
