$container-full-width-max-width: 1440px;

.o-container-full-width {
  max-width: $container-full-width-max-width;
  margin-left: auto;
  margin-right: auto;

  @each $breakpoint in map-keys($grid-gutter-widths) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($grid-gutter-widths, $breakpoint);
      padding-right: ($gutter * 1.5);
      padding-left:  ($gutter * 1.5);
    }
  }

}
