.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (#{$modal-dialog-margin} * 2));
}

.modal-header {
  padding-left: spacing(medium);
  padding-right: spacing(medium);
}

.modal-title {
  @include typi('base');
}

.modal-body {
  @include typi('small');
  padding: spacing(medium);
}
