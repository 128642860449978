.o-row-full-width {
  @include media($only-mobile-tablet) {
    margin-left: 0;
    margin-right: 0;
  }
}

@mixin reset-space($gutter) {
  margin-left: -($gutter * 1.5);
  margin-right: -($gutter * 1.5);
  padding-left: ($gutter * 1.5);
  padding-right: ($gutter * 1.5);
}

.o-row-full-width__col {
  width: auto;
  @include media($only-mobile-tablet) {
    $gutter: map-get($grid-gutter-widths, md);
    @include reset-space($gutter);
  }

  @include media($only-mobile) {
    $gutter: map-get($grid-gutter-widths, sm);
    @include reset-space($gutter);
  }

  @include media($only-mobile-small) {
    $gutter: map-get($grid-gutter-widths, xs);
    @include reset-space($gutter);
  }

}
