/*
===========================================
==  Paleta de colores  ====================
===========================================
*/

$azul: #41C6EF;
$azul-oscuro: #174E5F;

$grey-1: #222;
$grey-2: #e5e5e5;

$body-bg: #fff; // color del fondo de la página

$selection-text-color: $azul; // color del texto seleccionado
$body-color: $grey-1; // color del texto por defecto
$inverse-text-color: #fff; // color para fondos oscuros

$link-color: $azul;
$link-decoration: none;
$link-hover-color: darken($azul,15%);
$link-hover-decoration: underline;

$rojo: #a94442;
$rojo1: #f2dede;

/*
===========================================
==  breakpoints  ==========================
===========================================
*/

// breakpoints de bootstrap
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$grid-columns: 12;
$grid-gutter-widths: (
  xs: 10px,
  sm: 15px,
  md: 20px,
  lg: 30px,
  xl: 40px
);

// breakpoints para typi
$breakpoints: (
  xs: map-get($grid-breakpoints, xs),
  sm: map-get($grid-breakpoints, sm),
  md: map-get($grid-breakpoints, md),
  lg: map-get($grid-breakpoints, lg),
  xl: map-get($grid-breakpoints, xl),
);

// breakpoint aliases
$mobile-large: '>=sm';
$tablet: '>=md';
$desktop: '>=lg';
$desktop-large: '>=xl';

$only-mobile-small: '<sm';
$only-mobile: '<md';
$only-mobile-tablet: '<lg';
$only-down-desktop: '<xl';

/*
===========================================
== fuentes ================================
===========================================
*/
$modularscale: (
  base: 16px,
  ratio: 1.25
);

$font-family-base: Avenir, 'Nunito Sans', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-size-base: rem(ms(0));
$font-weight-base: normal;
$line-height-base: 1.5;
$headings-line-height: 1.1;
$buttons-line-height: 1.1;

$black-weight: 900;

// Nombre de tamaños de letra y sus comportamientos
$typi: (
  base: (
    null: (ms(0), $line-height-base),
  ),
  tiny: (
    xl: ( ms(-2), $line-height-base ),
  ),
  small: (
    null: ( ms(-1), $line-height-base ),
    md: ( ms(0) )
  ),
  normal: (
    null: ( ms(0), $line-height-base ),
    md: ( ms(1) ),
  ),
  large: (
    null: ( ms(1), $line-height-base )
  ),
  h3: (
    null: ( ms(0), $headings-line-height ),
    md: ( ms(2) )
  ),
  h2: (
    null: ( ms(1), $headings-line-height ),
    md: ( ms(3) )
  ),
  h1: (
    null: ( ms(2), $headings-line-height ),
    md: ( ms(4) )
  ),
  button: (
    null: ( ms(0), $buttons-line-height )
  ),
  button-large: (
    null: ( ms(0), $buttons-line-height ),
    md: ( ms(1) ),
  ),
  button-small: (
    null: ( ms(-1), $buttons-line-height ),
  )
);

/*
===========================================
==  márgenes  =============================
===========================================
*/

$spacing: (
  tiny:    vr(0.25), //  24px * 0.25 = 6px
  xsmall:  vr(0.5),  //  24px * 0.5  = 12px
  small:   vr(0.75), //  24px * 0.75 = 18px
  base:    vr(1),    //  16px * 1    = 24px
  medium:  vr(1.5),  //  24px * 1.5  = 36px
  large:   vr(2),    //  24px * 2    = 48px
  huge:    vr(2.75), //  24px * 2.75 = 66px
  xhuge:   vr(4),    //  24px * 4    = 96px
  xxhuge:  vr(6),    //  24px * 6    = 144px
);



/*
===========================================
==  themes  ===============================
===========================================
*/

$themes: (
);



/*
===========================================
==  forms  ================================
===========================================
*/
$input-padding-x: 1.5rem;
$input-padding-y: 0.7rem;
$input-line-height: 2;
$input-color: $grey-1;
$input-color-placeholder: $inverse-text-color;
$input-bg: transparent;
$input-border-color: $inverse-text-color;
$input-border-radius: rem(28px);

$state-danger-text:   $rojo;
$state-danger-bg:     $rojo1;






